@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply p-4 lg:p-8 bg-gray-200;
}

.chord-pro {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
